import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BreakpointsService } from './breakpoints.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CustomBreakpointNames } from './breakpoints.service';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveService {
  activeBreakpoints: string[] = [];
  currentBreakpoint: string;
  public handsetPortrait: boolean;
  public handsetLandscape: boolean;
  public tabletPortrait: boolean;
  public tabletLandscape: boolean;
  public laptop: boolean;
  public desktop: boolean;
  public mobileLayout = this.breakpointObserver.isMatched(CustomBreakpointNames.laptop) ? false : true;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private breakpointService: BreakpointsService) {

    breakpointObserver.observe(this.breakpointService.getBreakpoints()).subscribe(result => {
      this.handsetLandscape = result.breakpoints[this.breakpointService.getBreakpoint(CustomBreakpointNames.handsetLandscape)];
      this.tabletPortrait = result.breakpoints[this.breakpointService.getBreakpoint(CustomBreakpointNames.tabletPortrait)];
      this.tabletLandscape = result.breakpoints[this.breakpointService.getBreakpoint(CustomBreakpointNames.tabletLandscape)];
      this.laptop = result.breakpoints[this.breakpointService.getBreakpoint(CustomBreakpointNames.laptop)];
      this.mobileLayout = !this.laptop;
    });
  }

  subscribeToLayoutChanges(): Observable<string[]> {
    return this.breakpointObserver
      .observe(this.breakpointService.getBreakpoints())
      .pipe(map((observeResponse) => this.parseBreakpointsResponse(observeResponse.breakpoints)));
  }

  parseBreakpointsResponse(breakpoints): string[] {
    this.activeBreakpoints = [];

    Object.keys(breakpoints).map((key) => {
      if (breakpoints[key]) {
        this.activeBreakpoints.push(this.breakpointService.getBreakpointName(key));
      }
    });

    return this.activeBreakpoints;
  }

  getActiveBreakpoint() {
    this.currentBreakpoint = this.activeBreakpoints[this.activeBreakpoints.length - 1];
  }

  isBreakpointActive(breakpointName) {
    return this.activeBreakpoints.find(breakpoint => breakpoint === breakpointName);
  }
}
