import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot,RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
 
import { StateManager } from "../services/stateManager.service";

@Injectable()
export class CatalogAuthGuard implements CanActivate {
  constructor(private manager: StateManager, private router:Router) {}

  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree 
  {
    if(this.manager.delegate && (this.manager.delegate.isAllowedPortuges || this.manager.localOrder) && this.manager.meetingGuid) return true;
    else if(this.manager.meetingGuid) return this.router.navigate(["/showroom"]);
    else return this.router.navigate(["/"]);
  }
}