import { TOUCH_BUFFER_MS } from "@angular/cdk/a11y";
import { ProductGroup } from "./productGroup.model";

export class ClientOrderItem {

    public sku:string;
    public name:string;
    public label:string;
    public picturePath:string;
    
    public quantity:number;
    public unitPrice:number;
    

    public total : number ;


}