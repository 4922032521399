import { BrowserModule, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { LayoutModule } from './shared/layouts/layout.module';
import { SidenavModule } from './shared/layouts/components/sidenav/sidenav.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { MessageService } from 'primeng/api';
import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { CustomLoader } from './core/utils/langCustomLoader';

import * as Sentry from "@sentry/angular";

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { ResponsiveService } from './core/services/responsive.service';
import { Router } from '@angular/router';
import { MyHammerConfig } from './my-hammer.config';
import { CatalogAuthGuard } from './core/CatalogAuthGuard/catalogAuthGuard.service';

registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SidenavModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    HammerModule,
    NgxGoogleAnalyticsModule.forRoot('G-9N3NZ7G7SF'),
    NgxGoogleAnalyticsRouterModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomLoader,
        deps: [HttpClient]
      }
    })
  ],
  exports: [],
  providers: [
    CatalogAuthGuard,
    ResponsiveService,
    CookieService,
    MessageService,
    { provide: LOCALE_ID, useValue: 'fr' },
    {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler({
          showDialog: false
        }),
      },
      {
        provide: Sentry.TraceService,
        deps: [Router],
      },
      {
        provide: APP_INITIALIZER,
        useFactory: () => () => {},
        deps: [Sentry.TraceService],
        multi: true,
      },{
        provide: HAMMER_GESTURE_CONFIG,
        useClass: MyHammerConfig
      }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  
}


