import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
// import { MaterialModule } from '../utils/material-components.module';
import { SidenavModule } from './components/sidenav/sidenav.module';
import { ToolbarModule } from './components/toolbar/toolbar.module';
import { LayoutComponent } from './pages/layout.component';

@NgModule({
    declarations: [
        LayoutComponent,

    ],
    exports: [
    ],
    imports: [
        CommonModule,
        RouterModule,
        // MaterialModule,
        SidenavModule,
        ToolbarModule
    ]
})
export class LayoutModule {
}
