import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogModule } from 'primeng/dynamicdialog';
import {DialogModule} from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { ToastModule } from 'primeng/toast';
import { InputTextModule } from 'primeng/inputtext';
import { TabViewModule } from 'primeng/tabview';
import { BadgeModule } from 'primeng/badge';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ButtonModule } from 'primeng/button';
import {PanelModule} from 'primeng/panel';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {DataViewModule} from 'primeng/dataview';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {StepsModule} from 'primeng/steps';
import {RatingModule} from 'primeng/rating';
import {TableModule} from 'primeng/table';
import {SidebarModule} from 'primeng/sidebar';
import {CardModule} from 'primeng/card';
import {RadioButtonModule} from 'primeng/radiobutton';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { TagModule } from 'primeng/tag';
import {AccordionModule} from 'primeng/accordion';
import {MessageModule} from 'primeng/message';
import { AvatarModule } from 'primeng/avatar';
import {CheckboxModule} from 'primeng/checkbox';
import {VirtualScrollerModule} from 'primeng/virtualscroller';
import {SliderModule} from 'primeng/slider';
import { ChipModule } from 'primeng/chip';
import {ProgressBarModule} from 'primeng/progressbar';
import {TooltipModule} from 'primeng/tooltip';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    DynamicDialogModule,
    DialogModule,
    DropdownModule,
    InputNumberModule,
    ToastModule,
    InputTextModule,
    TabViewModule,
    BadgeModule,
    InputSwitchModule,
    ButtonModule,
    PanelModule,
    OverlayPanelModule,
    DataViewModule,
    ScrollPanelModule,
    StepsModule,
    RatingModule,
    TableModule,
    SidebarModule,
    CardModule,
    RadioButtonModule,
    ProgressSpinnerModule,
    TagModule,
    AccordionModule,
    MessageModule,
    AvatarModule,
    CheckboxModule,
    VirtualScrollerModule,
    SliderModule,
    ChipModule,
    ProgressBarModule,
    TooltipModule
  ],
  providers: [DialogService, DynamicDialogConfig]

})
export class NgPrimeModule {
}
