import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import { CatalogAuthGuard } from './core/CatalogAuthGuard/catalogAuthGuard.service';

const routes: Routes = [

        {
                path: '',
                //component: LayoutComponent,
                children: [
                        {
                                path: '',
                                loadChildren: () => import('./features/landing/landing.module').then(m => m.LandingModule)
                        },
                        {
                                path: 'video',
                                loadChildren: () => import('./features/video/video.module').then(m => m.VideoModule)
                        },
                        {
                                path: 'mollie',
                                loadChildren: () => import('./features/mollie/mollie.module').then(m => m.MollieModule)
                        },
                        {
                                path: 'confirmation',
                                loadChildren: () => import('./features/confirmation/confirmation.module').then(m => m.ConfirmationModule)
                        },
                        {
                                path: 'catalog',
                                canActivate: [CatalogAuthGuard],
                                loadChildren: () => import('./features/catalogue/catalogue.module').then(m => m.CatalogueModule)
                                
                        },
                ]
        },
        {       
                path: '**',
                redirectTo: ''
        }
        ];

@NgModule({
        imports: [RouterModule.forRoot(routes, {
                initialNavigation: 'enabled',
                // preloadingStrategy: PreloadAllModules,
                scrollPositionRestoration: 'enabled',
                anchorScrolling: 'enabled',
                relativeLinkResolution: 'legacy'
        })],
        exports: [RouterModule]
})
export class AppRoutingModule { }