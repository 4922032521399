import { ClientOrder } from "./clientOrder.model";
import { ClientOrderItem } from "./clientOrderItem.model";
import { LocalOrderClient } from "./localOrderClient.model";
import { LocalOrderError } from "./localOrderError.model";
import { LocalOrderItem } from "./localOrderItem.model";
import { LocalOrderItemForUpdate } from "./localOrderItemForUpdate.model";
import { LocalOrderVoucher } from "./localOrderVoucher.model";
import { ProductGroup } from "./productGroup.model";

export class LocalOrder {

        public id: number = 0;
        public statusId: number

        public itemsTotal: number = 0;
        public vouchersTotal: number = 0;
        public deliveryFee: number = 0;
        public total: number = 0;
        public totalWithVouchers: number = 0;
        public paidTotal: number = 0;

        public deliveryTypeId: number = 0;
        public paymentStatusId: number;

        public languageCode: string;

        public orderItems: LocalOrderItem[];
        public vouchers: LocalOrderVoucher[];
        public client: LocalOrderClient;

        public hasErrors: boolean;
        public errors: LocalOrderError[];

        constructor() {
                this.orderItems = [];
                this.vouchers = [];
                this.errors = [];
                this.hasErrors = false;
                this.load();
        };

        load(): void {
                let storedOrder: any = localStorage.getItem("order");
                if (storedOrder) {
                        storedOrder = JSON.parse(storedOrder);
                        for (var key in storedOrder) {
                                this[key] = storedOrder[key]
                        }
                        this.recalculate();
                }
        }

        loadFromApi(apiOrder: any): void {
                if (apiOrder) {
                        //apiOrder = JSON.parse(apiOrder);
                        for (var key in apiOrder) {
                                if (key == "orderItems") {
                                        this.orderItems = this.handleOrderItemsFromApi(apiOrder[key]);
                                } else this[key] = apiOrder[key]
                        }
                }

                this.save();
        }

        handleOrderItemsFromApi(apiItems: LocalOrderItem[]): LocalOrderItem[] {
                let items = [];
                this.orderItems.forEach((i) => {
                        let exists = apiItems.find(x => x.sku == i.sku);
                        if (exists) {
                                i.unitPrice = exists.unitPrice;
                                i.quantity = exists.quantity;
                                i.total = exists.total;
                                if (exists.productGroup != null) {
                                        i.productGroup = exists.productGroup;
                                }
                                items.push(i);
                        }
                });

                return items;
        }

        save(): void {
                localStorage.setItem("order", JSON.stringify(this));
        }

        recalculate(): void {
                this.orderItems.forEach(e => e.total = e.quantity * e.unitPrice);
                this.itemsTotal = this.orderItems.reduce((a, b) => a + b.total, 0);
                this.vouchersTotal = this.vouchers.reduce((a, b) => a - b.amount, 0);


                //TEST FAKE VOUCHERS
                //this.vouchersTotal = -50;
                //TO REMOVE!!
                
                this.total = this.itemsTotal + this.vouchersTotal;
                this.totalWithVouchers = this.itemsTotal + this.vouchersTotal;

                if (this.total < 0) this.total = 0;

                this.total += this.deliveryFee;
                
                this.save();
        }

        addItem(orderItem: LocalOrderItem): void {
                let existing = this.orderItems.find(o => o.sku === orderItem.sku);
                if (existing) {
                        existing.quantity += orderItem.quantity;
                        existing.unitPrice = orderItem.unitPrice;
                } else {
                        this.orderItems.push(orderItem);
                }

                if(this.orderIsEgiftOnly()) this.deliveryFee = 0.00;
                else if(this.deliveryTypeId == 10) this.deliveryFee = 5.90;

                this.recalculate();
        }

        updateItem(orderItemForUpdate: LocalOrderItemForUpdate): void {
                if (orderItemForUpdate) {
                        let existing = this.orderItems.find(o => o.sku === orderItemForUpdate.originalSku);
                        if (existing) {
                                existing.sku = orderItemForUpdate.sku;
                                existing.quantity = orderItemForUpdate.quantity;
                                existing.unitPrice = orderItemForUpdate.unitPrice;
                        };
                        this.recalculate();
                }
        }

        removeItem(sku: string): void {
                this.orderItems = this.orderItems.filter(o => o.sku !== sku);

                if(this.orderIsEgiftOnly()) this.deliveryFee = 0.00;
                else if(this.deliveryTypeId == 10) this.deliveryFee = 5.90;

                this.recalculate();
        }

        addVoucher(orderVoucher: LocalOrderVoucher): string {
                let existing = this.vouchers.find(o => o.code === orderVoucher.code);
                if (existing) {
                        return "Existing";
                } else {
                        this.vouchers.push(orderVoucher);
                }
                this.recalculate();
                return "Ok";
        }

        removeVoucher(code: string): void {
                this.vouchers = this.vouchers.filter(o => o.code !== code);

                this.recalculate();
        }

        addClient(client: LocalOrderClient): void {
                this.client = client;
        }

        getClientOrder(): ClientOrder {
                let clientOrder : ClientOrder = new ClientOrder();

                clientOrder = {...this, orderItems: []}

                this.orderItems.forEach((i) => {
                       let item= new ClientOrderItem();
                        item.sku= i.sku;
                        item.name= i.productGroup.name;
                        item.label= i.productGroup.label;
                        item.quantity= i.quantity;
                        item.unitPrice= i.unitPrice;
                        item.total= i.total;
                        item.picturePath= i.productGroup.productImage;
                        clientOrder.orderItems.push(item);
                       }
                );

                // clientOrder = {...this, orderItems: [...this.orderItems]}

                // clientOrder.orderItems.forEach((i) => {
                //         i['productGroup']= undefined;
                //         }
                // );

                return clientOrder;

        }

        orderIsEgiftOnly():boolean{
                if(this.orderItems.length==0) return false;

                let totalOI = this.orderItems.length;
                let totalEGift = 0;

                this.orderItems.forEach(x => {
                        if(x.sku.startsWith("EGIFT")) totalEGift++;
                })

                return totalOI==totalEGift;
        }
        

}