export class CountryCode{

    displayName         :       string;
    countryCode        :       string;
    isSelected          :       boolean;
    
    constructor(displayName: string ,countryCode: string){
            this.displayName= displayName;
            this.countryCode= countryCode;
    }
}