export class UserInfo {
        public meetingGuid: string
        public attendeeGuid?: string
        public token?: string

        constructor(meetingGuid: string, attendeeGuid?: string, token?: string) {
                this.meetingGuid = meetingGuid;
                this.attendeeGuid = attendeeGuid;
                this.token= token;
        };


}