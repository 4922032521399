import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";

Sentry.init({
        dsn: "https://8ecb25cc3b9047d6b504f1ec7d51ad67@o578616.ingest.sentry.io/5734957",
        integrations: [
          new Integrations.BrowserTracing({
            tracingOrigins: ["https://staging.agora-victoria.com", "https://staging-api.agora-victoria.com", "https://myshowroom-api.agora-victoria.com", "https://myshowroom.agora-victoria.com"],
            routingInstrumentation: Sentry.routingInstrumentation,
          }),
        ],
      
        release: "agora@" + environment.APP_VERSION,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.8,
        environment: (environment.ENVIRONMENT),
        debug :false,
        denyUrls: ["localhost"],
      });
      

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
