import { ProductGroup } from "./productGroup.model"

export class Capsule {
    public id : string
    public languageCode : string
    public name : string
    public productsCount : number
    public products : ProductGroup[]

    public isActif: boolean
    public isLoaded: boolean
    public isLoading: boolean

    constructor(){
        this.products = [];
        this.isActif = false;
        this.isLoaded = false;
        this.isLoading = false;
    }



}
