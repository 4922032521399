export class LanguageCode {

    displayName         :       string;
    languageCode        :       string;
    isSelected          :       boolean;
    
    constructor(displayName: string ,languageCode: string){
            this.displayName= displayName;
            this.languageCode= languageCode;
    }
}