import { Injectable } from '@angular/core';
import * as UAParser from "ua-parser-js";

@Injectable({
    providedIn: 'root'
})
export class UAService {

    private parser: UAParser

    constructor() {
        this.parser = new UAParser();
    }

    public getResult(){
        return this.parser.getResult();
    }

    public getOS() {
        return this.getResult()?.os.name;
    }

    public getOSVersion() {
        return this.getResult()?.os.version;
    }

    public getOSMajorVersion() {

        let osVersion = this.getResult()?.os.version;
        if(osVersion) return parseInt(osVersion.split(".")[0]);
        else return 0;
    }


    public getBrowser() {
        return this.getResult()?.browser.name;
    }

    public getBrowserVersion() {
        return this.getResult()?.browser.version;
    }

    public getBrowserMajorVersion() {

        let browserVersion = this.getResult()?.browser.version;
        if(browserVersion) return parseInt(browserVersion.split(".")[0]);
        else return 0;
    }

    public isOSCompatible(): boolean {

        let os = this.getOS();

        if (os == "Android") return this.getOSMajorVersion() > 6;
        else if (os == "iOS") return this.getOSMajorVersion() > 8;
        else return true;
    }

    public isAppleDevice(){
        let os = this.getOS();

        switch (os) {
            case "Mac OS X":
            case "Mac OS":
            case "iOS": return true;
            default: return false;
        }
    }

    //[Compatible for OS, Compatible for browser version]
    public isBrowserCompatibleForOS(): boolean[] {

        let os = this.getOS();
        let browser = this.getBrowser();
        let version = this.getBrowserMajorVersion();

        if (browser == 'Facebook') return [false,false];

        switch (os) {
            case "Windows":
                switch (browser) {
                    case "Firefox": return [true,version >= 75];
                    case "Opera": return [true,version >= 66];
                    case "IE": return [false,false];
                    case "Edge": return [true,version >= 79];
                    case "Chrome": return [true,version >= 78];
                    case "Chromium": return [true, version >= 78];
                    case "Electron": return [true, true];
                    default: return [false,false];
                }
            case "Mac OS X":
            case "Mac OS":
                switch (browser) {
                    case "Firefox": return [true,version >= 75];
                    case "Chrome": return [true,version >= 78];
                    case "Chromium": return [true, version >= 78];
                    case "Safari": return [true,version >= 13];
                    case "Mobile Safari": return [true,version >= 13];
                    case "Opera": return [true,version >= 66];
                    case "Edge": return [true,version >= 79];
                    case "Brave": return [true, true];
                    case "Electron": return [true, true];
                    default: return [false,false];
                }
            case "Linux":
                switch (browser) {
                    case "Chrome": return [true,version >= 78];
                    case "Electron": return [true, true];
                    default: return [false,false];
                }
            case "Android":
                switch (browser) {
                    case "Samsung Browser": return [true,version >= 12];
                    case "Chrome": return [true,true];
                    case "Chromium": return [true, version >= 78];
                    case "Chrome WebView": return [true,version >= 5];
                    default: return [false,false];
                }
            case "iOS":
                switch (browser) {
                    case "Firefox": return [true,version >= 10];
                    case "Chrome": return [true,version >= 10];
                    case "Chromium": return [true, version >= 78];
                    case "Safari": return [true,version >= 13];
                    case "Mobile Safari": return [true,version >= 13];
                    default: return [false,false];
                }
            case "Chromium OS":
                switch (browser) {
                    case "Chrome": return [true,version >= 78];
                    case "Chromium": return [true, version >= 78];
                    case "Electron": return [true, true];
                    default: return [false,false];
                }
            default: return [false,false];
        }
    }
}