import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
// import { MaterialModule } from "src/app/shared/utils/material-components.module";
import { ToolbarFullscreenToggleComponent } from "./components/toolbar-fullscreen-toggle/toolbar-fullscreen-toggle.component";
import { ToolbarUserComponent } from "./components/toolbar-user/toolbar-user.component";
import { ToolbarComponent } from "./toolbar.component";


@NgModule({
    declarations: [
        ToolbarComponent,
        ToolbarUserComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        // MaterialModule,
    ],
    exports: [
        ToolbarComponent,
    ]
})
export class ToolbarModule {

}