import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { TranslateLoader } from "@ngx-translate/core";
import { Observable, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { IObjectResponse } from "../models/interfaces/IObjectResponse.model";
import { catchError, map } from "rxjs/operators";

const BASE_URL: string = environment.API_BASE_PATH + '/api/';
const headers = new HttpHeaders()
        .set('content-type', 'application/json')
        .set('Access-Control-Allow-Origin', '*')

export class CustomLoader implements TranslateLoader {

        constructor(private http: HttpClient) {}
      
        public getTranslation(lang: String): Observable<any> {
                return this.http.get<any>(BASE_URL + "admin/resources/" + lang,  { headers: headers }).pipe(
                        catchError(this.handleError)
                );
                // return this.http.get<any>("./assets/langs/" + lang + ".json",  { headers: headers }).pipe(map(res => res)).pipe(
                //         catchError(this.handleError)
                // );
      
        }

        private handleError(error: HttpErrorResponse) {
                if (error.error instanceof ErrorEvent) {
                        // A client-side or network error occurred. Handle it accordingly.
                        console.error('An error occurred:', error.error.message);
                } else {
                        // The backend returned an unsuccessful response code.
                        // The response body may contain clues as to what went wrong.
                        console.error(
                                `Backend returned code ${error.status}, ` +
                                `body was: ${error.error}`);

                }
                // Return an observable with a user-facing error message.
                return throwError(
                        error.message);
        }

      }