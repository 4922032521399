import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// import { MatIconModule } from '@angular/material/icon';
// import { MatListModule } from '@angular/material/list';
// import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { SidenavItemComponent } from './sidenav-item/sidenav-item.component';
import { SidenavService } from './services/sidenav.service';
// import { MaterialModule } from 'src/app/shared/utils/material-components.module';
import { SidenavMenu } from './sidenav.component';



@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    // MaterialModule,

  ],
  declarations: [SidenavMenu,SidenavItemComponent],
  exports: [SidenavMenu],
  providers: [SidenavService]
})
export class SidenavModule {
}
