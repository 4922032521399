import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';


@Injectable({
        providedIn: 'root'
})
export class LocalStorageService {

        constructor(private cookieService: CookieService) { }

        defaultExpiration : number= 30;

        private set(key: string, value: any, expireInDays?: number){
                if (expireInDays)
                        this.cookieService.set(key, String(value), { expires: expireInDays, path: '/' } );
                        else this.cookieService.set(key, String(value), { path: '/' } );
        }

        private getString(key: string) : string {
                return this.cookieService.get(key);
        }

        private getBoolean(key: string) : boolean {
                return (this.cookieService.get(key)=='true');
        }


        public set isDelegate(value: boolean){
                this.set('id', value);
        }
        public get isDelegate() : boolean {
                return this.getBoolean('id');
        }

        public set languageCode(value: string){
                this.set('lc', value, this.defaultExpiration);
        }
        public get languageCode() : string {
                return this.getString('lc');
        }

        public set meetingGuid(value: string){
                this.set('mg', value, this.defaultExpiration);
        }
        public get meetingGuid() : string {
                return this.getString('mg');
        }

        public set attendeeGuid(value: string){
                this.set('ag', value, this.defaultExpiration);
        }
        public get attendeeGuid() : string {
                return this.getString('ag');
        }

        public set clientName(value: string){
                this.set('cn', value, this.defaultExpiration);
        }
        public get clientName() : string {
                return this.getString('cn');
        }

        public set clientEmail(value: string){
                this.set('ce', value, this.defaultExpiration);
        }
        public get clientEmail() : string {
                return this.getString('ce');
        }

        public set meetingToken(value: string){
                this.set('mt', value);
        }
        public get meetingToken() : string {
                return this.getString('mt');
        }

        public set attendeeToken(value: string){
                this.set('at', value, this.defaultExpiration);
        }
        public get attendeeToken() : string {
                return this.getString('at');
        }

        //session cookie
        public set canMuteUnMute(value: boolean){
                this.set('cmu', value);
        }
        public get canMuteUnMute() : boolean {
                return this.getBoolean('cmu');
        }

        public set delegateEshop(value:string){
                this.set("des", value);
        }

        public get delegateEshop() : string {
                return this.getString("des");
        }

        //session Storage
        public set chimeMeeting(value: any){
                sessionStorage.setItem('cm', JSON.stringify(value));
        }
        public get chimeMeeting() : any {
                return JSON.parse(sessionStorage.getItem('cm'));
        }
        
        //session Storage
        public set chimeAttendee(value: any){
                sessionStorage.setItem('ca', JSON.stringify(value));
        }
        public get chimeAttendee() : any {
                return JSON.parse(sessionStorage.getItem('ca'));
        }


        //Paiement Info
        public set paymentDatas(value: any){
                sessionStorage.setItem('pi', JSON.stringify(value));
        }

        public get paymentDatas():any{
                return JSON.parse(sessionStorage.getItem('pi'));
        }

        //Audio Video Device
        public set choosenVideoDeviceId(value: any){
                sessionStorage.setItem('dvid', JSON.stringify(value));
        }

        public get choosenVideoDeviceId():any{
                return JSON.parse(sessionStorage.getItem('dvid'));
        }

        public set choosenAudioDeviceId(value: any){
                sessionStorage.setItem('daid', JSON.stringify(value));
        }

        public get choosenAudioDeviceId():any{
                return JSON.parse(sessionStorage.getItem('daid'));
        }

        public set choosenAudioOutDeviceId(value: any){
                sessionStorage.setItem('daoid', JSON.stringify(value));
        }

        public get choosenAudioOutDeviceId():any{
                return JSON.parse(sessionStorage.getItem('daoid'));
        }

        //Client Data
        public set clientDatas(value: any){
                sessionStorage.setItem('cds', JSON.stringify(value));
        }

        public get clientDatas(): any{
                return JSON.parse(sessionStorage.getItem('cds'));
        }


         //Self disconnection
        public set selfDisconnected(value: boolean){
                sessionStorage.setItem('sd', JSON.stringify(value));
        }
        public get selfDisconnected() : boolean {
                return JSON.parse(sessionStorage.getItem('sd'));
        }
        public destroySelfDisconnected(){
                sessionStorage.removeItem('sd');
        }

        //Self Context
        public set context(value:number){
                this.set('ctxid', value);
        }

        public get context():number{
                let context = this.getString('ctxid');
                if (context) return Number(context);
                else return 0;
        }

        //Catalogue Filters
        public set filters(data:any){
                if(data==null) localStorage.removeItem('f');
                else localStorage.setItem('f',JSON.stringify(data));
        }

        public get filters():any{
                return JSON.parse(localStorage.getItem('f'));
        }

        //Cookie Consent
        public set consent(data:any){
                if(data==null) localStorage.removeItem('consent');
                else localStorage.setItem('consent',JSON.stringify(data));
        }

        public get consent():any{
                return JSON.parse(localStorage.getItem('consent'));
        }

        public set mutedUsers(data:string[]){
                if(data==null) localStorage.removeItem('mutedUsers');
                else localStorage.setItem('mutedUsers',JSON.stringify(data));
        }

        public get mutedUsers():string[]{
                return JSON.parse(localStorage.getItem('mutedUsers'))??[];
        }

        public get isLocalMuted():boolean {
                return this.getBoolean('localMuted')??false;
        }

        public set isLocalMuted(data:boolean){
                this.set('localMuted',data,0.25);
        }

        public get isVideoMuted():boolean {
                return this.getBoolean('videoMuted')??false;
        }

        public set isVideoMuted(data:boolean){
                this.set('videoMuted',data,0.25);
        }

}

