import { ClientOrder } from "./clientOrder.model";


export class ClientOrders {

        public total: number = 0;
        public paidTotal: number = 0;

        public clientOrders: ClientOrder[];

        constructor() {
                this.clientOrders = [];
        };

        public setClientOrders(orders: ClientOrder[]) {
                if (orders) {
                        this.clientOrders= [...orders];
                        this.clientOrders.forEach(o=> o.total = (o.itemsTotal - o.vouchersTotal>0)? o.itemsTotal - o.vouchersTotal: 0);
                        this.total =  this.clientOrders.reduce((a, b) => a + b.total, 0);
                        this.paidTotal =  this.clientOrders.reduce((a, b) => (b.statusId==3)? a + b.total : 0, 0);
                }
               
        }

      

        

}