import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '@app/core/services/localStorage.service';

@Component({
  selector: 'agora-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent implements OnInit {

  constructor(public storage:LocalStorageService) { }

  ngOnInit(): void {
  }

  consent(){
    this.storage.consent = true;
  }

}
