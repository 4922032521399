import { ClientOrderItem } from "./clientOrderItem.model";
import { LocalOrder } from "./localOrder.model";
import { LocalOrderClient } from "./localOrderClient.model";
import { LocalOrderError } from "./localOrderError.model";
import { LocalOrderItem } from "./localOrderItem.model";
import { LocalOrderItemForUpdate } from "./localOrderItemForUpdate.model";
import { LocalOrderVoucher } from "./localOrderVoucher.model";

export class ClientOrder {

        public id: number = 0;
        public statusId: number

        public itemsTotal: number = 0;
        public vouchersTotal: number = 0;
        public deliveryFee: number = 0;
        public total: number = 0;
        public paidTotal: number = 0;

        public deliveryTypeId: number = 0;
        public paymentStatusId: number;

        public orderItems: ClientOrderItem[];
        public vouchers: LocalOrderVoucher[];
        public client: LocalOrderClient;


        constructor() {
                this.orderItems = [];
                this.vouchers = [];
        };

      

        

}