import {Injectable} from '@angular/core';

export const CustomBreakpointNames = {
  handsetPortrait: 'handset-portrait',
  handsetLandscape: 'handset-landscape',
  tabletPortrait: 'tablet-portrait',
  tabletLandscape: 'tablet-landscape',
  laptop: 'laptop and big tablet',
  desktop: 'desktop'
};

@Injectable({
  providedIn: 'root'
})

export class BreakpointsService {
  breakpoints: object = {
    '(min-width: 480px) and (orientation: landscape)': CustomBreakpointNames.handsetLandscape,
    '(min-width: 768px) and (orientation: portrait)': CustomBreakpointNames.tabletPortrait,
    '(min-width: 1024px) and (orientation: landscape)': CustomBreakpointNames.tabletLandscape,
    '(min-width: 1024px)': CustomBreakpointNames.laptop,
    '(min-width: 1200px)': CustomBreakpointNames.desktop
  };

  getBreakpoints(): string[] {
    return Object.keys(this.breakpoints);
  }

  getBreakpointName(breakpointValue): string {
    return this.breakpoints[breakpointValue];
  }

  getBreakpoint(value): string {
    return Object.keys(this.breakpoints).find(key => this.breakpoints[key] === value);
  }

  constructor() {
  }
}
