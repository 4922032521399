import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StateManager } from './core/services/stateManager.service';
import { SidenavService } from './shared/layouts/components/sidenav/services/sidenav.service';

@Component({
  selector:'agora-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'My Showroom - Victoria';

  constructor(private sidenavService: SidenavService, private translate: TranslateService, private state: StateManager) {

        this.translate.addLangs(['fr_fr', 'nl_be', 'fr_be', 'de_de', 'pt_pt']);
        
    
        //const browserLang = this.translate.getBrowserLang();
        //this.translate.use(browserLang.match(/fr|nl|de/) ? browserLang : 'fr_fr');

        if (this.state.languageCode)
                this.translate.use(this.state.languageCode);
                else {
                        this.translate.setDefaultLang('fr_fr');
                }

    // this.sidenavService.addItems([
    //   {
    //     name: 'APPS',
    //     position: 5,
    //     type: 'subheading',
    //     customClass: 'first-subheading'
    //      },
    //   {
    //     name: 'Home',
    //     routeOrFunction: '/',
    //     icon: 'dashboard',
    //     position: 10,
    //     pathMatchExact: true
    //   },
    //   {
    //     name: 'Ma Démo',
    //     routeOrFunction: '/Victoria',
    //     icon: 'assignment',
    //     badgeColor: '#2196F3',
    //     position: 15,
    //   }
    //   ,
    //   {
    //     name: 'Video',
    //     routeOrFunction: '/Video',
    //     icon: 'assignment',
    //     badgeColor: '#2196F3',
    //     position: 15,
    //   },
    //   {
    //     name: 'Connexion',
    //     routeOrFunction: '/login',
    //     icon: 'date_range',
    //     position: 20
    //   },
    // ]);


  }
}
