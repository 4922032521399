import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { VideoTileComponent } from './components/video-tile/video-tile.component';
import { LayoutModule } from './layouts/layout.module';
import { LoadingIndicatorModule } from './loading-indicator/loading-indicator.module';
import { PendingInterceptorModule } from './loading-indicator/pending-interceptor.module';
import { NgPrimeModule } from './utils/ngprime-components.module';
import { CarouselComponent } from './components/carousel/carousel.component';
import { LogoComponent } from './components/logo/logo.component';
import { RatingComponent } from './components/rating/rating.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';

import { OrderListComponent } from './components/order-list/order-list.component';
import { ChimeSettingsComponent } from './components/chime-settings/chime-settings.component';
import { TilesDisplayModalComponent } from './components/tiles-display-modal/tiles-display-modal.component';
import { ClientTileWrapperComponent } from '@app/features/video/components/client-tile-area/client-tile-wrapper/client-tile-wrapper.component';

import { OrderComponent } from './components/order/order.component';
import { DetailsOrderComponent } from './components/order/components/details-order/details-order.component';
import { VoucherOrderComponent } from './components/order/components/voucher-order/voucher-order.component';
import { TotalOrderComponent } from './components/order/components/total-order/total-order.component';
import { DeliveryOrderComponent } from './components/order/components/delivery-order/delivery-order.component';
import { ErrorOrderComponent } from './components/order/components/error-order/error-order.component';
import { OrderDesktopComponent } from './components/order-desktop/order-desktop.component';
import { DeliveryOrderDesktopComponent } from './components/order-desktop/components/delivery-order-desktop/delivery-order-desktop.component';
import { DetailsOrderDesktopComponent } from './components/order-desktop/components/details-order-desktop/details-order-desktop.component';
import { ErrorOrderDesktopComponent } from './components/order-desktop/components/error-order-desktop/error-order-desktop.component';
import { TotalOrderDesktopComponent } from './components/order-desktop/components/total-order-desktop/total-order-desktop.component';
import { VoucherOrderDesktopComponent } from './components/order-desktop/components/voucher-order-desktop/voucher-order-desktop.component';
import { CookieConsentComponent } from './components/cookie-consent/cookie-consent.component';
import { CatalogueComponent } from './components/catalogue/catalogue.component';
import { ChipsAreaComponent } from './components/catalogue/components/chips-area/chips-area.component';
import { VirtualScrollerModule } from "ngx-virtual-scroller";
import { OrderItemDetailsComponent } from '@app/features/video/components/orderItemDetails/orderItemDetails.component';
import { MediaSettingsComponent } from './media-settings/media-settings.component';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { GuideDesTaillesComponent } from './components/guide-des-tailles/guide-des-tailles.component';
import { ContextualHelpComponent } from './components/contextual-help/contextual-help.component';
import { CircularMenuComponent } from './components/circular-menu/circular-menu.component';


@NgModule({
  exports: [
    CommonModule,
    RouterModule,
    LoadingIndicatorModule,
    PendingInterceptorModule,
    LayoutModule,
    NgPrimeModule,
    TranslateModule,
    CarouselComponent,
    LogoComponent,
    VideoTileComponent,
    OrderListComponent,
    ChimeSettingsComponent,
    ClientTileWrapperComponent,
    OrderComponent,
    DetailsOrderComponent,
    VoucherOrderComponent,
    TotalOrderComponent,
    DeliveryOrderComponent,
    ErrorOrderComponent,
    CookieConsentComponent,
    CatalogueComponent,
    ChipsAreaComponent,
    OrderItemDetailsComponent,
    MediaSettingsComponent,
    CircularMenuComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgPrimeModule,
    TranslateModule,
    VirtualScrollerModule,
    DynamicDialogModule
  ],
  declarations: [
    CarouselComponent,
    LogoComponent,
    RatingComponent,
    ConfirmationModalComponent,
    VideoTileComponent,
    OrderListComponent,
    ChimeSettingsComponent,
    TilesDisplayModalComponent,
    ClientTileWrapperComponent,
    OrderComponent,
    DetailsOrderComponent,
    VoucherOrderComponent,
    TotalOrderComponent,
    DeliveryOrderComponent,
    ErrorOrderComponent,
    OrderDesktopComponent,
    DeliveryOrderDesktopComponent,
    DetailsOrderDesktopComponent,
    ErrorOrderDesktopComponent,
    TotalOrderDesktopComponent,
    VoucherOrderDesktopComponent,
    CookieConsentComponent,
    CatalogueComponent,
    ChipsAreaComponent,
    OrderItemDetailsComponent,
    MediaSettingsComponent,
    GuideDesTaillesComponent,
    ContextualHelpComponent,
    CircularMenuComponent
]
})
export class SharedModule {
}
