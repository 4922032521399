import { Capsule } from "./capsule.model"
import { ProductGroup } from "./productGroup.model"

export class OnAirNavigationState {
    public asset : Capsule
    public index : number = 0;
    
    constructor(asset: Capsule){
        this.asset= asset;
        this.index=0;

    }

    

}